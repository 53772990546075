exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reg-js": () => import("./../../../src/pages/reg.js" /* webpackChunkName: "component---src-pages-reg-js" */),
  "component---src-pages-rider-1-js": () => import("./../../../src/pages/rider/1.js" /* webpackChunkName: "component---src-pages-rider-1-js" */),
  "component---src-pages-rider-10-js": () => import("./../../../src/pages/rider/10.js" /* webpackChunkName: "component---src-pages-rider-10-js" */),
  "component---src-pages-rider-2-js": () => import("./../../../src/pages/rider/2.js" /* webpackChunkName: "component---src-pages-rider-2-js" */),
  "component---src-pages-rider-20-js": () => import("./../../../src/pages/rider/20.js" /* webpackChunkName: "component---src-pages-rider-20-js" */),
  "component---src-pages-rider-3-js": () => import("./../../../src/pages/rider/3.js" /* webpackChunkName: "component---src-pages-rider-3-js" */),
  "component---src-pages-rider-4-js": () => import("./../../../src/pages/rider/4.js" /* webpackChunkName: "component---src-pages-rider-4-js" */),
  "component---src-pages-rider-index-js": () => import("./../../../src/pages/rider/index.js" /* webpackChunkName: "component---src-pages-rider-index-js" */),
  "component---src-pages-user-reg-js": () => import("./../../../src/pages/user-reg.js" /* webpackChunkName: "component---src-pages-user-reg-js" */)
}

